import React from "react";
import Layout from "../../components/layout/Layout";
import { useStaticQuery, graphql } from "gatsby";
import ImageSection from "../../components/ui/sections/ImageSection";
import TagsSection from "../../components/ui/sections/TagsSection";

const NieladakawaPage = () => {
  const query = useStaticQuery(graphql`
    query {
      articleImg: file(relativePath: { eq: "portfolio/nieladakawa.png" }) {
        childImageSharp {
          gatsbyImageData(width: 600)
        }
      }
    }
  `);

  const tags = [
    "Gatsby.js",
    "architektura informacji",
    "integracja z mediami społeczniościowymi",
    "nowoczesny projekt graficzny",
    "intuicyjny interfejs użytkownika",
    "optymalizacja prędkości",
  ];

  return (
    <Layout pageTitle="nieladakawa.pl">
      <main className="container mx-auto px-5">
        <ImageSection
          subtitle="Strona marketingowa"
          title="Wizytówka dla dystrybutora kawy"
          image={query.articleImg.childImageSharp.gatsbyImageData}
        >
          <p className="text-gray-700 text-base mb-4">
            Wykonaliśmy stronę firmową odznaczającą się nowoczesnym designem
            oraz dużą szybkością działania. Strona jest wyjątkowo lekka i
            pozbawiona niepotrzebnych obciążeń po stronie serwera.
          </p>
          <p className="text-gray-700 text-base mb-4">
            Ze względów bezpieczeńtstwa, strona została wygenerowana statycznie,
            co czyni ją odporną na większość ataków hakerskich wykorzystujących
            znane dziury popularnych systemów CMS. Nie jest wymagana
            aktualizacja żadnego systemu CMS, a dane mogą pochodzić z dowolnego
            źródła.
          </p>
        </ImageSection>
        <TagsSection title="Projekt w pigułce" tags={tags} />
      </main>
    </Layout>
  );
};

export default NieladakawaPage;
